@import 'src/Theme/Theme';

.core-services-tabs {
  @extend .container;
  * {
    color: black;
  }

  .csp-tabs-header-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 30px 0;

    .header-index {
      @extend .news-paper-fonts;
      font-size: 2.5em;
    }

    .header-label {
      @extend .space-text-sm;
      text-align: center;
      font-size: 0.6em;
      letter-spacing: 0;
    }
  }

  .csp-tab-panel {
    .csp-tp-caption {
      @extend .section-heading;
    }

    .csp-tp-heading {
      @extend .news-paper-fonts;
      max-width: 1000px;
      font-weight: 200;
      font-size: 35px;
      margin: 40px auto;
      text-align: center;
    }

    .csp-tp-paragraph {
      font-weight: 500;
      max-width: 700px;
      margin: auto;
      text-align: center;
      line-height: 1.6;
      text-transform: unset;
      letter-spacing: 0;
      font-size: 0.8em;
    }

    .csp-tp-columns {
      @extend .flex;
      flex-direction: row;
      grid-gap: 80px;
      position: relative;

      @media (max-width: $breakpoint-tablet) {
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 0;

        .csp-tp-column {
          margin: 10px 0;
        }
      }

      .csp-tp-column {
        position: relative;
        margin: 60px 0;

        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 33.33%;
          width: 33.33%;
          border-top: 1px solid #010101;
        }

        &::before {
          top: 0;
        }

        .csp-tp-column-heading {
          @extend .space-text-sm;
          font-weight: 600;
          font-size: 1em;
          text-align: center;
          margin: 40px auto;
        }

        .csp-tp-column-paragraph {
          @extend .csp-tp-paragraph;
          margin-bottom: 40px;
        }
      }
    }

    .csp-tp-banner-row {
      @extend .grid;
      grid-template-columns: repeat(2, 1fr);
      border-top: 1px solid black;
      margin: 70px auto;
      padding: 70px 0;

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 1fr;
      }

      & > div {
        padding-right: 60px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 0;
        }

        &:nth-child(1) {
          border-right: 1px solid black;

          @media (max-width: $breakpoint-tablet) {
            border-right: none;
          }
        }
      }

      .csp-tp-quote {
        @extend .flex;
        flex-direction: column;
        align-items: center;

        .quote-icon {
          height: 100px;

          &.upside-down {
            transform: rotate(180deg);
          }
        }

        .quote {
          font-weight: 500;
          max-width: 700px;
          margin: auto;
          text-align: center;
          line-height: 1.6;
          text-transform: unset;
          letter-spacing: 0;
          font-size: 1.4em;
        }

        .narrator {
          @extend .news-paper-fonts;
          margin: 30px auto;
          text-align: center;
          font-size: 2.5em;
        }

        .narrator-additional {
          @extend .space-text-sm;
          margin: auto;
          text-align: center;
        }
      }

      .csp-tp-banner {
        padding: 10px;
        .csp-tp-banner-content {
          @extend .flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid white;
          padding: 30px;
          * {
            color: white;
            text-align: center;
          }
        }
      }
    }

    .csp-tp-button-row {
      @extend .flex;
      flex-direction: column;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      align-content: center;
      justify-content: center;
      height: 150px;

      &[hidden] {
        display: none;
      }

      a {
        @extend .subscribe;
        margin: auto;
      }
    }
  }

  .p-tabview.p-component {
    margin: 50px 0;

    .p-tabview-nav-container {
      margin-bottom: 50px;

      .p-tabview-nav-content {
        .p-tabview-nav {
          border-bottom: none;
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: repeat(8, 1fr);

          .p-unselectable-text {
            border-right: 1px solid black;

            &:nth-child(1) {
              border-left: 1px solid black;
            }

            a {
              margin: auto;
              border-bottom: none;
              justify-content: center;

              &:focus {
                box-shadow: unset;
              }
            }
          }

          .p-unselectable-text.p-tabview-selected.p-highlight {
            .p-tabview-nav-link {
              background: #ffffff;
              border-color: black;
              color: black;
              border-radius: 0;
            }
          }

          .p-tabview-ink-bar {
            display: none;
          }
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
    }
  }

  .p-accordion {
    margin-top: 30px;
    .p-accordion-content {
      padding: 0;
      border: none;
      background: white;
    }

    .p-accordion-header-link {
      background: white !important;
      border-top: 1px solid black !important;
      border-bottom: 1px solid black !important;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
}
