@import 'src/Theme/Theme';

.hp-lifecycle-card {
  .card-background {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;

    .card-background-image {
      position: absolute;
      width: 100%;
      height: 300px;
      object-fit: cover;
      z-index: -10;
    }

    .card-content {
      padding: 20px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(rgba(0, 0, 0, 0.13) 50%, rgba(0, 0, 0, 0.63));

      h3 {
        @extend .news-paper-fonts;
        font-size: 30px;
        color: white;
        margin: 0;
      }
    }

    .lcc-caption {
      padding: 10px 20px;
      background-color: $theme-color-dark-tone-4;

      line-height: 1.5;
    }
  }
}
