@import "src/Theme/Theme";

.featured-projects-section {
    @extend .section-margin;

    .fp-cards-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px 20px;

        @media (max-width: $breakpoint-tablet) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}