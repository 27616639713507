@import "ThemeClasses";
@import "ThemeParameters";
@import "ThemeLayout";
@import "ThemeColors";
@import "ThemeBase";

.p-inputwrapper {
    @extend .default-text-box;
    padding: 0;

    .p-component {
        border: none !important;
    }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: $theme-color-primary !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    border-radius: 5px !important;
}

.p-datepicker-calendar {
    td:has(.p-highlight[tabindex="0"]) {
        border: 2px solid red;
    }
    .p-highlight[tabindex="0"] {

    }
}

.p-datepicker table td > span.p-highlight {
    color: $theme-color-primary !important;
}

.p-datepicker table td > span.p-highlight {
    background-color: $theme-color-lite !important;
}

//.p-button {
//    background: $theme-color-primary !important;
//    color: white !important;
//    border: 1px solid $theme-color-primary-hover !important;
//
//    &:hover {
//        background: $theme-color-primary-hover !important;
//    }
//
//    .p-button-icon {
//        color: white !important;
//    }
//}

.p-button.p-component.p-button-success {
    @extend .default-button;
    span {
        color: white;
    }
}

.p-input-icon-left.bueno-text-box {
    padding: 0;
    width: auto;
    input {
        height: 100%;
        width: 100%;
    }
}

.p-inputtext:enabled:hover {
    border-color: $theme-color-primary !important;
}

.p-inputtext {
    &.round {
        border-radius: 300px;
        padding-left: 50px;
    }
}

.p-input-icon-left {
    i.pi {
        left: 1.25rem !important;
    }
    .p-inputtext {
        &.round {
            border-radius: 300px;
            padding-left: 50px;
        }
    }
}

.p-sidebar-bottom .p-sidebar {
    height: auto !important;
}

.p-sidebar-close.p-sidebar-icon.p-link, .p-input-switch.p-focus {
    box-shadow: 0 0 0 0.2rem $theme-color-lite !important;
}

.pr-input-switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
}