@import 'src/Theme/Theme';

.footer {
  background-color: white;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 60px 0;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0 50px 0;
  }

  .footer-content {
    @extend .container;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    grid-gap: 50px;

    .footer-column {
      display: grid;
      grid-gap: 10px;
      justify-content: start;
      height: auto;
      margin-bottom: auto;
    }

    a {
      color: $theme-color-dark;
      text-transform: uppercase;
      cursor: pointer;

      &.selected {
        font-weight: bolder;
      }

      &:hover {
      }
    }

    .logo-text {
      margin-bottom: 10px;
    }

    .footer-logo {
      height: 50px;
      margin: 20px 0;
    }

    .footer-description {
      max-width: 350px;
    }

    label {
      font-size: 0.8em;
    }

    .small-text {
      font-size: 0.5em;
    }

    .social-media {
      grid-gap: 10px;
      font-size: 2em;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid $basic-font-color;
    margin: 60px 0;
  }
}
