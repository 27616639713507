@import "src/Theme/Theme";

.home-page-development-lifecycle-section {
    @extend .standard-padding;

    .intro-heading {
        @extend .news-paper-fonts;
        max-width: 900px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .intro {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 30px;
    }

    .lifestyle-card-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;

        @media (max-width: $breakpoint-tablet) {
            grid-template-columns: 1fr;
        }
    }
}