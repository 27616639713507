@import "src/Theme/Theme";

.home-page-featured-developments-section {
    @extend .standard-padding;

    .carousel-grid {
        width: 100%;
        display: grid;
        position: relative;
        grid-gap: 15px;

        @media (min-width: $breakpoint-tablet) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: repeat(2, 250px) repeat(2, 350px);
            grid-template-areas:
                'child_1 child_1 child_2'
                'child_1 child_1 child_3'
                'child_4 child_4 child_4'
                'child_5 child_6 child_6';

            & > div:nth-child(1) {
                grid-area: child_1;
            }

            & > div:nth-child(2) {
                grid-area: child_2;
            }

            & > div:nth-child(3) {
                grid-area: child_3;
            }

            & > div:nth-child(4) {
                grid-area: child_4;
            }

            & > div:nth-child(5) {
                grid-area: child_5;
            }

            & > div:nth-child(6) {
                grid-area: child_6;
            }
        }

        @media (max-width: $breakpoint-tablet) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(6, 250px);
        }
    }

    .images-slideshow {
        overflow: hidden;
        //height: 400px;
        position: relative;

        img {
            position: absolute;

            &:nth-child(1) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:nth-child(2) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                animation: image-fade-two 9000ms infinite linear;
                animation-delay: 3000ms;
            }

            &:nth-child(3) {
                width: 200%;
                height: 100%;
                object-fit: cover;
                animation: image-fade 9000ms infinite linear;
            }
        }

        .image-slideshow-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.62));

            h2 {
                @extend .news-paper-fonts;
                margin: 0;
                color: white;
                font-size: 2.5em;
            }
        }
    }

    @keyframes image-fade {
        0% {
            opacity: 0;
            transform: translateX(-100px);
        }
        33% {
            opacity: 0;
            transform: translateX(-100px);
        }
        66% {
            opacity: 1;
            transform: translateX(-50px);
        }
        100% {
            opacity: 0;
            transform: translateX(0);
        }
    }

    @keyframes image-fade-two {
        0% {
            opacity: 0;
            //transform: translateY(-100px);
            transform: scale(1.5);
        }
        33% {
            opacity: 0;
            //transform: translateY(-100px);
            transform: scale(1.5);
        }
        66% {
            opacity: 1;
            //transform: translateY(-50px);
            transform: scale(1.25);
        }
        100% {
            opacity: 1;
            //transform: translateY(0);
            transform: scale(1);
        }
    }
}