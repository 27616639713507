@import 'src/Theme/Theme';

.project-fact-description-section {
  .pfv-description-grid {
    @extend .grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 30px;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }

    .pfv-content {
      .pfv-content-heading {
        @extend .news-paper-fonts;
        margin-top: 0;
      }

      .pfv-content-paragraph {
        font-size: 0.9em;
        line-height: 1.6em;
      }
    }

    .pfv-features-col {
      border-left: 1px solid black;
      margin-bottom: auto;

      @media (max-width: $breakpoint-tablet) {
        border-left: unset;
      }

      .pfv-feature {
        padding: 15px 20px;

        @media (max-width: $breakpoint-tablet) {
          padding: 10px 0;
        }

        .pfv-feature-label {
          @extend .space-text-sm;
          width: 100%;
        }

        .pfv-feature-value {
          font-size: 1.5em;
          font-weight: 500;
          width: 100%;
        }

        .pfv-feature-value-other {
          font-size: 15px;
          font-weight: 500;
          width: 100%;

          @media (max-width: 700px) {
            font-size: 17px;
          }
        }

        .pfv-register-button {
          @extend .subscribe;
        }
      }
    }
  }

  .pfv-g-map-container {
    position: relative;
    text-align: right;
    width: 100%;
    margin-top: 30px;

    .pfv-g-map-iframe {
      border: none;
      width: 100%;
      height: 500px;
    }
  }
}
