@import 'src/Theme/Theme';

.home-page-featured-headline-section {
  @extend .standard-padding;

  .news-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    line-height: 1.5;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }

    .news-header {
      @extend .news-paper-fonts;
      font-size: 35px;
    }
  }

  .color-white {
    color: white;
  }

  .banner-content {
    text-align: center;

    .hp-fh-sec-banner-date {
    }

    .hp-fh-sec-banner-name {
      @extend .dmsd;
      font-weight: 500;
      font-size: 1.5rem;
      margin: 20px 0;
    }

    .hp-fh-sec-banner-place {
    }

    .hp-fh-sec-banner-pb {
    }

    .hp-fh-sec-banner-host {
      font-size: 1.6rem;
    }

    .logo {
      // height: 50px;
      margin: 20px 0;
      width: 100%;
    }
  }
}
