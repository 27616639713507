@import "ThemeColors";

* {
    //color: $basic-font-color;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    //background-image: url('site-background.svg');
    //background-repeat: no-repeat;
    //background-attachment: fixed;
    //background-size: cover;
    //backdrop-filter: blur(70px) ;
}