@import 'src/Theme/Theme';

.vp-statement-section {
  @extend .container;

  .vp-statement-heading {
    @extend .news-paper-fonts;
    text-align: center;
  }

  .vp-statement-statement {
    width: 600px;
    margin: 20px auto;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.6;
  }
}

.vp-leadership-section {
  @extend .container;

  .vp-leadership-team-grid {
    @extend .grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

.auth-img {
  width: 100%;
}

.sub-upper-text {
  font-weight: 700;
  text-transform: uppercase;
}
