@import 'ThemeColors';

.merriweather {
  font-family: 'Merriweather', serif;
}

.nunito {
  font-family: 'Nunito', sans-serif;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;
}

.apple-systems {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.cinzel {
  font-family: 'Cinzel', serif;
}

.dmsd {
  font-family: 'DM Serif Display', serif;
}

.news-paper-fonts {
  @extend .dmsd;
  font-size: 40px;
  font-weight: 300;

  @media (max-width: 700px) {
    font-size: 30px;
  }
}

.default-font {
  @extend .apple-systems;
}

.bold {
  font-weight: 600;
}

.standard-line-height {
  line-height: 1.5;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.section {
}

.section-padding {
  padding-top: 140px;
  padding-bottom: 140px;
}

.section-margin {
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: 700px) {
    margin-top: 0px;
  }
}

.heading {
  @extend .merriweather;
}

.heading-one {
  @extend .heading;
  font-size: 3.3em;
  line-height: 1.7;
}

.heading-two {
  @extend .default-font;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-three {
  font-size: 1.2em;
  line-height: 1.7;
}

.heading-three-sub {
  @extend .news-paper-fonts;
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
}

.heading-four {
  font-size: 1.4em;
  line-height: 1.7;
  font-weight: 300;
  margin: 5px 0;
}

.space-text-sm {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 0.7em;
  // color: gray;

  @media (max-width: 700px) {
    font-size: 15px;
  }
}

.section-heading {
  @extend .heading-two, .merriweather;
  font-size: 1em;
  text-align: center;
  color: $theme-color-dark;
  border-top: 1px solid $theme-color-dark;
  border-bottom: 1px solid $theme-color-dark;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;

  &::after {
    content: '';
    height: 5px;
    background-color: black;
  }
}

.section-heading-sub {
  @extend .heading-four-sub;
  font-size: 1.2em !important;
  color: $basic-font-color-light;
  text-align: center;
  font-weight: 300;
}

.section-small-heading {
  @extend .apple-systems;
  text-transform: capitalize;
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 700;
  color: $theme-color-dark;
}

.section-small-pre-heading {
  @extend .open-sans;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: 700;
  //padding: 5px 15px;
  //border-radius: 5px;
  //background: $theme-color-secondary-tone-4;
  color: $theme-color-primary;
  //margin-left: -15px;

  & + .section-small-heading {
    margin-top: 10px;
  }
}

.heading-four-sub {
  font-size: 1.2em;
  line-height: 1.7;
  font-weight: 400;
  margin: 20px 0;
}

.section-sub-heading {
  @extend .heading-three;
  @extend .merriweather;
  font-size: 2em;
  font-weight: 500;
  text-transform: capitalize;
}

.top-no-margin {
  margin-top: 0 !important;
}

.default-button {
  line-height: 1.3;
  font-family: Lato, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  transform-origin: center center;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  background-color: $theme-color-primary;
  border: 1px solid transparent;
  transition: all 0.3s ease 0s, transform 0.5s ease 0s;
  box-shadow: $shadow-primary;
  transition-duration: 200ms;

  &.round {
    border-radius: 300px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &:hover {
    background-color: $theme-color-primary-hover;
  }
}

.default-text-box {
  line-height: 1.3;
  font-family: Lato, 'Helvetica Neue', Arial, sans-serif;
  outline: none;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  transform-origin: center center;
  padding: 15px;
  font-size: 14px;
  border-radius: 5px;
  color: $basic-font-color;
  background-color: white;
  border: 1px solid #f1f1f1;
  transition: all 0.3s ease 0s, transform 0.5s ease 0s;
  box-shadow: $shadow-dark;

  &.round {
    border-radius: 300px;
  }
}

.logo-text {
  @extend .dmsd;
  font-weight: 700;
  letter-spacing: 5px;
  font-size: 1.8rem;
  margin: 20px 0;
  text-align: center;

  span {
    @extend .open-sans;
    font-size: 0.5rem;
  }
}

.colored-background {
  background-color: $theme-color-lite;
}

.shade-background {
  background-color: $background-color-light-shade;
}

.colored-text {
  color: $theme-color-primary;
}

.dark-theme-colored-text {
  color: $theme-color-primary-dark;
}

.subscribe {
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  color: black;
  padding: 10px 20px;
  margin-right: auto;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background: black;
    color: white !important;
  }
}

.subscribe-invert {
  border: 1px solid white;
  background: black;
  color: white !important;
  border-radius: 10px;
  padding: 10px 20px;
  margin-right: auto;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background: white;
    color: black;
  }
}
