@import 'src/Theme/Theme';

$light-color: white;
$dark-color: black;
$dark-color: black;

.navbar {
  position: fixed;
  width: 100%;
  background: $light-color;
  //backdrop-filter: blur(30px);
  z-index: $navbar-z-index;

  .nav-container {
    height: auto;
    display: grid;
    grid-template-rows: auto auto;
    @extend .container;

    &,
    * {
      transition-duration: 500ms;
      //transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    }

    .logo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .logo {
        width: auto;
        height: 60px;
        margin: 20px 0;
      }

      .logo-text {
        color: $dark-color;
        font-size: 4em;
      }
    }

    .navbar-tab-container {
      @extend .flex;
      justify-content: center;
      grid-gap: 30px;
      border-top: 1px solid $dark-color;
      border-bottom: 1px solid $dark-color;

      a {
        text-transform: uppercase;
        padding: 15px 0;
        cursor: pointer;
        font-size: 0.8rem;
        color: $dark-color;

        &.selected {
          border-bottom: 2px solid $dark-color;
        }
      }
    }

    .popup-tabs {
      display: none;
    }

    .button-container {
      display: none;
      margin-left: auto;

      .btn-format {
        color: $dark-color !important;
      }
    }
  }

  &.shrink {
    border-bottom: 1px solid #eae8e8;
    background: $light-color;

    .nav-container {
      height: auto !important;
      grid-template-rows: auto;
      grid-template-columns: auto 1fr;
      @extend .container;
      //margin: auto;
      //width: 1300px;

      .logo-container {
        justify-content: start;

        .logo {
          height: 38px;
          width: auto;
        }

        .logo-text {
          color: $dark-color;
          font-size: 1.8em;
        }

        .dch-miami-logo {
          h1 {
            font-size: 2rem;
            color: $dark-color;
          }

          label {
            font-size: 0.4rem;
            color: $dark-color;
          }
        }

        .mla-logo {
          height: 60px;
          margin: 20px 0;
        }
      }

      .navbar-tab-container {
        justify-content: end;
        border: none !important;
        margin-top: 0;

        a {
          display: flex;
          align-items: center;
          color: $dark-color;

          &.selected {
            border-bottom: 2px solid $dark-color;
          }
        }

        @media (max-width: $breakpoint-tablet) {
          background: $light-color !important;
        }
      }

      .button-container {
        .btn-format {
          color: $dark-color !important;
        }
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .nav-container {
      height: auto !important;
      grid-template-rows: auto;
      grid-template-columns: auto 1fr;
      @extend .container;

      .logo-container {
        justify-content: start;

        .logo {
          height: 38px;
          width: auto;
        }

        .logo-text {
          font-size: 1.8em;
        }
      }

      .navbar-tab-container {
        display: none;
      }

      .navbar-tab-container.visible {
        width: 100vw !important;
        height: 100vh !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        grid-gap: 20px !important;
        position: fixed !important;
        flex-direction: column !important;
        background: $light-color;
        left: 0 !important;
        top: 0 !important;
      }

      .popup-tabs {
      }

      .button-container {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .navbar-white-space {
    height: 100px !important;
  }
}

.navbar-white-space {
  position: relative;
  height: $navbar-height;
  z-index: -999;

  &.shrink {
    //height: $shrink-navbar-height !important;
  }
}
