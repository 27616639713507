@import 'src/Theme/Theme';

.contact-us {
  background-color: white;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 60px 0;

  @media (max-width: $breakpoint-mobile) {
    padding: 0px 0 30px 0;
  }

  .contact-us-content {
    @extend .container;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    grid-gap: 50px;

    .contact-us-column {
      display: grid;
      grid-gap: 10px;
      justify-content: start;
      height: auto;
      margin-bottom: auto;

      p {
        margin: 0;
        font-size: 20px;
      }
    }

    a {
      color: $theme-color-dark;
      cursor: pointer;

      &.selected {
        font-weight: bolder;
      }
    }

    .logo-text {
      margin-bottom: 10px;
    }

    .contact-us-logo {
      height: 50px;
      margin: 20px 0;
    }

    .contact-us-description {
      max-width: 350px;
    }

    label {
      font-size: 0.8em;
    }

    .small-text {
      font-size: 0.5em;
    }

    .social-media {
      grid-gap: 10px;
      font-size: 2em;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid $basic-font-color;
    margin: 60px 0;
  }
}

.vp-statement-section {
  @extend .container;

  .vp-statement-heading {
    @extend .news-paper-fonts;
    text-align: center;
  }

  .vp-statement-statement {
    width: 600px;
    margin: 20px auto;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.6;
  }
}

.vp-leadership-section {
  @extend .container;

  .vp-leadership-team-grid {
    @extend .grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
