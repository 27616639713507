
$theme-color-primary: #000000;
$theme-color-primary-tone-2: #7a7a7a;
$theme-color-primary-tone-3: #9f9f9f;
$theme-color-primary-tone-4: #E8FCF6;

$theme-color-secondary: #FF8345;
$theme-color-secondary-tone-2: #FCCE3A;
$theme-color-secondary-tone-3: #FFDDCC;
$theme-color-secondary-tone-4: #FFF4EE;
$theme-color-secondary-tone-3-transparent: rgba(255, 131, 69, 0.22);
$theme-color-secondary-tone-4-transparent: rgba(255, 131, 69, 0.06);

$theme-color-dark: #2B2945;
$theme-color-dark-tone-2: #7B8B93;
$theme-color-dark-tone-3: #C4CBCF;
$theme-color-dark-tone-4: #F5F9F8;






$basic-font-color: rgb(74, 74, 74);
$basic-font-color-light: rgb(131, 131, 131);
$light-font-color: #ffffff;
$light-font-color-shade-two: #bebebe;
$light-font-color-shade-three: rgba(183, 183, 183, 0);

$background-color-dark: rgb(40, 40, 40);
$background-color-light-shade: rgb(247, 247, 247);

//$theme-color-primary: #1ba94c;
$theme-color-primary-2: #17b584;
$theme-color-primary-3: #3effdd;
$theme-color-primary-hover: #13a661;
//$theme-color-primary: #00AF77;
$theme-color-primary-dark: #007745;
//$theme-color-secondary: #B7F1E1;
$theme-color-lite: #f1f1f1;

$shadow-primary: rgba(0, 175, 119, 0.5) 0px 10px 40px -10px;
$shadow-l: rgb(0 0 0 / 16%) 0px 47px 46px -27px, rgb(0 0 0 / 6%) 0px 2px 12px 0px;
$shadow-dark: rgb(74 74 74 / 20%) 0px 10px 20px -10px;