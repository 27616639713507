@import 'src/Theme/Theme';

.core-services-page-banner {
  @extend .container;
  position: relative;
  background-color: rgb(255, 255, 255);

  @media (max-width: $breakpoint-tablet) {
    padding-top: 6em;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 0em;
  }

  $top-padding: 40px;

  .csp-banner {
    width: 100%;
    padding-top: $top-padding;
    height: calc(100vh - #{$navbar-height});
    object-fit: cover;
    z-index: -10 !important;

    @media (max-width: $breakpoint-mobile) {
      padding-top: 0em;
    }

    .csp-banner-content {
      color: white;
      position: absolute;
      width: calc(100% - 40px);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: $breakpoint-mobile) {
        width: calc(100%);
      }

      .csp-banner-heading {
        @extend .news-paper-fonts;
        text-align: center;
        max-width: 700px;

        &:after {
          content: '';
          background: white;
          border-bottom: 2px solid white;
          width: 10px;
          height: 10px;
        }
      }

      .csp-banner-caption {
        @extend .space-text-sm;
      }
    }

    .csp-banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
