.banner {
    overflow: hidden;
    width: 100%;
    position: relative;

    .banner-background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -10;
    }

    .banner-content {
        padding: 40px;
    }
}