@import 'src/Theme/Theme';

.home-page-banner-section {
  @extend .standard-padding;

  .hp-banner-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }

    h2,
    p {
      color: white;
    }

    h2 {
      @extend .news-paper-fonts;
      font-size: 3em;
    }

    .logo-text {
      color: white;
      text-align: center;
    }

    .logo {
      width: 100%;
      margin: 20px 0;
    }
  }
}
