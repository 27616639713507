$breakpoint-notebook: 1360px;
$breakpoint-tablet: 1000px;
$breakpoint-mobile: 700px;

@media (max-width: $breakpoint-mobile) {
  .container-padding {
    padding: 0 0px !important;
  }
}

.container {
  margin: auto;
  width: 1300px;
  padding: 0 20px;

  @media (max-width: $breakpoint-notebook) {
    width: 100%;
  }
}

.wide-container {
  @extend .container;
  //width: 1300px;
}

.container-narrow {
  margin: auto;
  width: 900px;
}

.grid {
  display: grid;
  grid-gap: 20px;
}

.grid-column {
  @extend .grid;
}

.flex-center-align {
  display: flex;
  align-items: center;
}

.flex-center {
  @extend .flex-center-align;
  justify-content: center;
  flex-direction: column;
}

.two-columns {
  @extend .grid;
  grid-template-columns: 1fr 1fr;
}

.three-columns {
  @extend .grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.four-columns {
  @extend .grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.standard-margin {
  @extend .standard-margin-top, .standard-margin-bottom;
}

.standard-padding-bottom {
  padding-bottom: 32px;
}

.standard-padding-top {
  padding-top: 32px;
}

.standard-margin-bottom {
  margin-bottom: 32px;
}

.standard-margin-top {
  margin-top: 32px;
}

.standard-padding {
  @extend .standard-padding-bottom, .standard-padding-top;
}

.bullet-point {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'bullet point' '. description';
  grid-gap: 10px;
  align-items: center;

  .bullet {
    grid-area: bullet;
  }

  .point {
    grid-area: point;
  }

  .description {
    grid-area: description;
  }
}
