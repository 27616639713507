@import "src/Theme/Theme";

.dch-person-card {
    @extend .grid;
    grid-gap: 0;
    cursor: pointer;
    border-bottom: 1px solid black;

    .pc-image {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    .pc-content {
        @extend .flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        grid-gap: 5px;

        .pc-sub-heading, .pc-action {
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: 0.7em;
            margin: 0;
        }

        .pc-heading {
            @extend .news-paper-fonts;
            font-size: 1.2em;
            text-transform: uppercase;
            margin: 0;
        }

        .pc-divider {
            margin: 0.5em auto;
            border-top: 3px solid black;
            width: 50px;
        }

    }

    .pc-details-button-container {
        @extend .flex;
        flex-direction: column;
        align-items: center;
        height: 20px;

        &[hidden] {
            display: none !important;
        }

        .pc-details-button {
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: 0.7em;
            border-radius: 10px;
            border: 1px solid black;
            background: white;
            padding: 10px 20px;
            transition-duration: 300ms;
            height: 40px;
            margin-bottom: -25%;
        }
    }

    &:hover {
        .pc-details-button {
            border-radius: 10px;
            border: 1px solid black;
            background: black;
            color: white;
        }
    }

}