@import 'src/Theme/Theme';

.home-page-section-one {
  @extend .standard-padding;

  .hp-grid {
    display: grid;
    grid-template-columns: 2fr;
    grid-gap: 10px;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }
  }

  .hp-paragraph {
    h2 {
      @extend .news-paper-fonts, .top-no-margin;
    }
  }

  .text-columns {
    margin-top: 20px;
    padding: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 2.1875em;
    -moz-column-gap: 2.1875em;
    column-gap: 2.1875em;
    line-height: 1.5;

    @media (max-width: $breakpoint-mobile) {
      column-count: 1;
    }
  }

  .hp-services {
    //margin-left: auto;
    .p-panelmenu {
      width: 100% !important;
    }
  }

  //.hp-services-panel-menu {
  //    .p-panelmenu-panel {
  //        margin-bottom: 0;
  //    }
  //    //.p-panelmenu.p-panelmenu-header {
  //        a {
  //            background: white !important;
  //            border-radius: 0 !important;
  //            border: none !important;
  //            border-bottom: 1px solid #4d4d4d !important;
  //        }
  //    //}
  //}
}
