@import 'src/Theme/Theme';

.project-fact-hero-section {
  margin: 30px 0;

  @media (max-width: $breakpoint-mobile) {
    margin: 0px 0 30px 0;
  }

  .pfv-city {
    @extend .space-text-sm;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 10px;
  }

  .pfv-name {
    @extend .news-paper-fonts;
    width: 100%;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;

    &:after {
      content: '';
      width: 200px;
      border-bottom: 2px solid black;
    }
  }

  .pfv-galleria-thumbnail {
    height: 100px;
  }

  .pfv-galleria-img {
    height: 90vh;
    object-fit: cover;

    @media (max-width: $breakpoint-mobile) {
      height: 50vh;
    }
  }
}
